import type { Slot } from "~~/types/Slot";

const dateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "short",
  weekday: "short",
};
const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};
const dateFormat = new Intl.DateTimeFormat("de-DE", dateOptions);
const timeFormat = new Intl.DateTimeFormat("de-DE", timeOptions);

export const formatNextSlot = (slot: Slot) => {
  const date = new Date(slot.date);
  const dateStr = dateFormat.format(date);
  const timeStr = timeFormat.format(date);

  return `${dateStr} • ${timeStr} Uhr`;
};

export function formatServiceDuration(seconds: number): string {
  const durationInMinutes = seconds / 60;

  if (durationInMinutes >= 60) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);

    const hoursStr = hours > 1 ? "Stunden" : "Stunde";

    return minutes > 0 ? `${hours} ${hoursStr} ${minutes} Minuten` : `${hours} ${hoursStr}`;
  }

  return `${durationInMinutes} Minuten`;
}
